import * as React from "react"
import Seo from "src/components/seo"
import AppLayout from "src/components/app/app-layout"
import {graphql} from "gatsby"

const title = "はじめてのずかん 利用規約";

const TermContent = () => {
  return (
    <>
      <h1>{title}</h1>
      <section>
        <p>知育アプリ：はじめてのずかん（以下「はじめてのずかん」といいます）をご利用いただき、ありがとうございます！利用者の皆様が安心して、はじめてのずかんを利用していただけるように、はじめてのずかん利用規約を定めています。はじめてのずかんをご利用の際には、この「はじめてのずかん利用規約」が適用されますので、ご利用の前に必ずお読みください。</p>
      </section>

      <section>
        <h2>第1条（総則）</h2>
        <ol>
          <li>当社は、ユーザー情報等の保護実現のため、個人情報保護法その他関連する法令等を遵守いたします。本保護方針は、本アプリ（本アプリに付随して当社が提供するサービスを含みます。）の利用に関して適用されます。</li>
          <li>当社が当社の運営するウェブサイト上に掲載する個人情報保護方針又は利用規約においてユーザー情報等の取扱いについて規定する場合、当該規約等の規定（利用目的等）に加えて、本保護方針の規定（利用目的等）が適用されるものとします。規約等の規定が本保護方針の規定と抵触する場合には、本保護方針の規定が優先されるものとします。</li>
        </ol>
      </section>

      <section>
        <h2>第1条 本利用規約の適用および変更</h2>
        <ol>
          <li>「はじめてのずかん利用規約」（以下「本利用規約」といいます。）は、株式会社DanRan（以下「当社」といいます。）が提供する知育アプリ：はじめてのずかん（以下「本サービス」といいます。）の利用条件を定めるものです。</li>
          <li>利用者（次条で定義します。以下、本条において同じです。）は、本サービスの新規利用および利用継続中において、本利用規約に同意されているものとみなされます。本利用規約に同意いただけない場合には、本サービスを利用することができません。</li>
          <li>当社は、相当の事由があると判断した場合には、利用者の事前の承諾を得ることなく、当社の判断により、本利用規約をいつでも変更することができるものとします。</li>
          <li>前項の変更後の本利用規約は、当社が別途定める場合を除いて、本サイト（次条で定義します。）上に表示した時点より効力を生じるものとします。また、本利用規約の変更の効力が生じた後、利用者が本サービスを利用した場合、または登録者（次条で定義します。）が当社の定める期間内にID登録（第3条で定義します。）の解約の手続をとらなかった場合には、利用者または登録者は、変更後の本利用規約に同意したものとみなされます。</li>
          <li>第3項の変更がなされた場合、当該変更より前に行われた利用者の行為についても、当該変更がなされた後の本利用規約が適用されます。</li>
          <li>本利用規約に示される権利を行使または実施しない場合でも、当社が当該権利を放棄するものではありません。</li>
        </ol>
      </section>

      <section>
        <h2>第2条 定義</h2>
        <p>本利用規約において使用する用語の意義は、次の各号に定めるとおりとします。</p>
        <ol>
          <li>「利用者」とは、ID登録（次条で定義します。）の有無にかかわらず、本サービスを利用する全ての方をいいます。</li>
          <li>「登録者」とは、ID登録（次条で定義します。）が完了した方をいいます。</li>
          <li>「ID」は、次条で定める意義を有します。</li>
          <li>「本サイト」とは、本サービスで提供されるコンテンツ（文章、画像、動画その他のデータを含みますが、これらに限りません。以下同じです。）が掲載されたウェブページをいいます。</li>
          <li>「登録情報」とは、登録者がID登録の手続時に登録した情報（メールアドレス、パスワード等を含みます。）をいいます。</li>
          <li>「届出情報」とは、利用者が当社に届出または提供した情報（登録情報は除きます。）をいいます。</li>
          <li>「取得情報」とは、当社が取得する利用者に関するすべての情報（登録情報、届出情報のほか、利用者のIPアドレス、利用状況、履歴、位置情報、利用端末、決済ができなかった場合はその事実等を含みます。）をいいます。</li>
          <li>「個人情報」とは、取得情報のうち、特定の利用者を識別することができる情報（他の情報と容易に照合することができ、それにより特定の利用者を識別することができることとなるものを含みます。）をいいます。</li>
          <li>「利用者属性に関する情報」とは、個人情報以外の取得情報（郵便番号や性別、職業、年齢、これまでにご利用いただいたサービスやご購入いただいた商品、ご覧になったページ・広告、ご利用時間帯、ご利用の方法、ご利用環境等を含みます。）をいいます。</li>
          <li>「知的財産権」とは、著作権、特許権、実用新案権、意匠権、商標権その他の知的財産権（それらの権利を取得し、またはそれらの権利につき登録等を出願する権利を含みます。）
          </li>
        </ol>
      </section>

      <section>
        <h2>第3条 ID登録</h2>
        <ol>
          <li>本サービスのうち登録者限定のサービスの利用を希望する方には、本利用規約に同意の上、当社所定の登録手続を行っていただきます（以下、当該登録を「ID登録」といいます。）。</li>
          <li>当社は、登録者に対して、はじめてのずかんID（以下、「ID」といいます。）を付与します。</li>
        </ol>
      </section>

      <section>
        <h2>第4条 登録情報の管理</h2>

        <ol>
          <li>登録者は、自己の責任において、自己の登録情報（メールアドレスおよびパスワードを含みますがこれに限りません。）を適切に管理および保管するものとし、これを第三者に利用させ、または貸与、譲渡、名義変更、売買等をしてはならないものとします。</li>
          <li>登録情報の管理不十分、使用上の過誤、第三者の利用等によって登録者に生じた不利益および損害に関する責任、または、登録情報が不正確または虚偽であったために登録者が被った一切の不利益および損害に関する責任は、すべて登録者自身が負うものとし、当社は、一切の責任を負わないものとします。</li>
          <li>当社は、本サービスのログイン時に入力されたメールアドレスおよびパスワードが、登録されたメールアドレスおよびパスワードと一致することを所定の方法により確認した場合、当該ログインを真正な登録者のログインとみなし、真正な登録者による利用とみなします。</li>
        </ol>
      </section>


      <section>
        <h2>第5条 登録情報または届出情報の変更</h2>
        <ol>
          <li>登録者は、登録情報または届出情報に変更があった場合、すみやかに当社の定める方法により当該変更内容を当社に届け出るものとします。この届出がない場合、当社は登録情報または届出情報の変更がないものとして取り扱います。</li>
          <li>登録者から前項の届出がないために、当社から登録者に対して通知等がなされず、または、遅延もしくは不着となったことにより、登録者その他の者に不利益または損害が生じた場合、当社は、一切の責任を負わないものとします。</li>
          <li>登録者は、次条に定める手続に従い、ID登録を解約することができます。</li>
        </ol>
      </section>

      <section>
        <h2>第6条 ID登録の解約</h2>
        <ol>
          <li>登録者は、本サービスのID登録を解約する場合、当社所定の手続にて解約手続を行うこととし、当該解約手続の完了をもって、本サービスのID登録が解約されたものとします。</li>
          <li>登録者が登録を解約した場合、本サービスの登録者のみが受けられるサービスの利用ができなくなり、当該解約者の全ての取得情報及び全ての本サービスに係る履歴が削除されます。</li>
        </ol>
      </section>

      <section>
        <h2>第7条 禁止事項</h2>
        <ol>
          <li>
            利用者は、本サービスの利用に際して、以下の行為を行ってはならないものとします。
            <ul>
              <li>当社、他の利用者または第三者（以下、総称して「他者」といいます。）の知的財産権を侵害する行為、または侵害するおそれのある行為</li>
              <li>他者の財産、プライバシーもしくは肖像権を侵害する行為、または侵害するおそれのある行為</li>
              <li>特定の他者の個人情報の提供</li>
              <li>一つのIDを複数人で共同して保持する行為</li>
              <li>本サービスの使用を停止ないしID登録を解約された登録者に代わりID登録をする行為</li>
              <li>他者を差別もしくは誹謗中傷し、または他者の名誉もしくは信用を毀損する行為</li>
              <li>アクセス可能な本サービスのコンテンツもしくは情報を改ざん、消去する行為</li>
              <li>当社のネットワークまたはシステム等に過度な負担をかける行為</li>
              <li>当社になりすます行為（詐称するためにメールヘッダ等の部分に細工を行う行為を含みます。）</li>
              <li>他者の登録情報を利用する行為</li>
              <li>有害なコンピュータプログラム等を送信し、または他者が受信可能な状態におく行為</li>
              <li>通常に本サービスを利用する行為を超えてサーバーに負荷をかける行為もしくはそれを助長するような行為、その他本サービスの運営・提供もしくは他の利用者による本サービスの利用を妨害し、またはそれらに支障をきたす行為</li>
              <li>サーバー等のアクセス制御機能を解除または回避するための情報、機器、ソフトウェア等を流通させる行為</li>
              <li>本サービスによって提供される機能を複製、修正、転載、改変、変更、リバースエンジニアリング、逆アセンブル、逆コンパイル、翻訳あるいは解析する行為</li>
              <li>本サービスの全部または一部を商業目的で、使用方法を問わず利用する行為（それらの準備を目的とした行為も含みます。）</li>
              <li>本サービスの他の利用者の情報の収集</li>
              <li>法令に基づき監督官庁等への届出、許認可の取得等の手続が義務づけられている場合に、当該手続を履行せずに本サービスを利用する行為、その他当該法令に違反し、または違反するおそれのある行為</li>
              <li>本サービスの運営を妨害する行為、他者が主導する情報の交換または共有を妨害する行為、信用の毀損または財産権の侵害等の当社に不利益を与える行為</li>
              <li>長時間の架電、同様の問い合わせの繰り返しを過度に行い、または義務や理由のないことを強要し、当社の業務に著しく支障を来たす行為</li>
              <li>反社会的勢力等への利益供与</li>
              <li>法令もしくは本利用規約に違反する行為、または公序良俗に違反する行為</li>
              <li>前各号の行為を直接または間接に惹起し、または容易にする行為</li>
              <li>その他当社が利用者の行為として不適切と判断する行為</li>
            </ul>
          </li>

          <li>
            利用者が以下の各号のいずれかに該当する場合には、当該利用者は、本サービスを利用することができないものとします。
            <ul>
              <li>暴力団</li>
              <li>暴力団員</li>
              <li>暴力団準構成員</li>
              <li>暴力団関係企業</li>
              <li>総会屋等、社会運動等標榜ゴロまたは特殊知能暴力集団等</li>
              <li>その他前各号に準じる者</li>
            </ul>
          </li>
        </ol>
      </section>

      <section>
        <h2>第8条 利用制限</h2>
        <ol>
          <li>当社は、利用者が以下の各号のいずれかに該当する場合には、当該利用者の承諾を得ることなく、当該利用者の本サービスの利用を制限することがあります。</li>
          <li>ワーム型ウィルスの感染、大量送信メールの経路等により、利用者が関与することにより第三者に被害が及ぶおそれがあると判断した場合</li>
          <li>利用者と電話、電子メール等による連絡がとれない場合</li>
          <li>利用者宛てに発送した郵便物が当社に返送された場合</li>
          <li>利用者が本利用規約のいずれかの条項に違反した場合</li>
          <li>その他利用者による利用が不適切と当社が判断した場合</li>
          <li>当社が前項に基づき利用者の本サービスの利用を制限したことにより、当該利用者が本サービスを利用できず、これにより不利益または損害が発生したとしても、当社は一切責任を負わないこととします。</li>
        </ol>
      </section>

      <section>
        <h2>第9条 本サービスの提供の停止・中断等</h2>
        <ol>
          <li>
            当社は、以下の各号のいずれかの事由が生じた場合には、利用者に事前に通知することなく、本サービスの全部または一部の提供を停止または中断することができるものとします。
            <ul>
              <li>本サービスを提供するための通信設備等の定期的な保守点検を行う場合または点検を緊急に行う場合</li>
              <li>火災、停電等により本サービスの提供ができなくなった場合</li>
              <li>地震、噴火、洪水、津波等の天災により本サービスの提供ができなくなった場合</li>
              <li>戦争、動乱、暴動、騒乱、労働争議等により本サービスの提供ができなくなった場合</li>
              <li>その他運用上または技術上の理由により当社が本サービスの提供の停止または中断が必要と判断した場合</li>
            </ul>
          </li>

          <li>当社は、当社の都合により、利用者に通知または告知することなく、本サービスの内容を変更し、または提供を終了することができるものとします。</li>
          <li>当社は、本条に基づき当社がとった措置に起因しまたは関連して、利用者に生じた不利益または損害について、一切の責任を負わないものとします。</li>
        </ol>
      </section>

      <section>
        <h2>
          第10条 利用環境の整備
        </h2>
        <ol>
          <li>利用者は、本サービスを利用するために必要な通信機器、ソフトウェアその他これらに付随して必要となる全ての機器を、自己の費用と責任において準備し、利用可能な状態に置くものとします。また、本サービスの利用にあたっては、自己の費用と責任において、利用者が任意に選択し、電気通信サービスまたは電気通信回線を経由してインターネットに接続するものとします。</li>
          <li>利用者は、関係官庁等が提供する情報を参考にして、自己の利用環境に応じ、コンピュータ・ウィルスの感染、不正アクセスおよび情報漏洩の防止等セキュリティを保持するものとします。</li>
          <li>当社は、利用者の利用環境について一切関与せず、また一切の責任を負わないものとします。</li>
        </ol>
      </section>

      <section>
        <h2>第11条 自己責任の原則</h2>
        <ol>
          <li>利用者は、利用者自身の責任において本サービスを利用するものとし、本サービスを利用してなされた一切の行為およびその結果についてその責任を負うものとします。</li>
          <li>利用者は、本サービスの利用に際し、当社または他者に不利益または損害を与えた場合、利用者自身の責任と費用において、損害を賠償し、解決にあたるものとします。</li>
        </ol>
      </section>

      <section>
        <h2>
          第12条 権利帰属
        </h2>
        <ol>
          <li>本サイトおよび本サービスに関する知的財産権は当社または当社にライセンスを許諾している者に帰属しており、本利用規約に基づく本サービスの利用許諾は、本サイトまたは本サービスに関する当社または当社にライセンスを許諾している者の知的財産権の使用許諾を意味するものではありません。</li>
          <li>本サービスのコンテンツは、知的財産権に関する法令等（著作権法、商標法、意匠法等を含みます。）により保護されております。</li>
          <li>本サービスおよび本サービスに関連して使用されているすべてのソフトウェアは、知的財産権に関する法令等により保護されている権利および営業秘密を含んでおります。</li>
        </ol>
      </section>

      <section>
        <h2>
          第13条 保証の否認および免責
        </h2>
        <ol>
          <li> 当社は、本サービスが利用者の特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、利用者による本サービスの利用が利用者に適用のある法令または業界団体の内部規則等に適合すること、および不具合が生じないことについて、何ら保証するものではありません。また、記事の内容につきましては、情報の確実性や安全性に関して、何ら保証するものではなく、医学の進歩に伴い最新の情報とは限りません。病気の原因・治療法・予防法などの医学的情報は、医師およびその他医療従事者による診断に代わるものではありません。個別の症状につきましては、必ず医師より適切な診断と治療を受けていただきますようお願いします。</li>
          <li> 当社が提供するコンテンツおよびソフトウェア等の情報は、当社がその時点で提供可能なものとし、その完全性、正確性、適用性、有用性、利用可能性、安全性、確実性等について、当社は、何ら保証するものではありません。</li>
          <li> 当社は、当社による本サービスの提供の中断、停止、終了、利用不能または変更、利用者が本サービスに送信したメッセージまたは情報の削除または消失、登録者の登録の抹消、本サービスの利用による登録情報の消失、データ破損等、または機器の故障もしくは損傷、その他本サービスに関して利用者が被った不利益または損害について、賠償する責任を一切負わないものとします。</li>
          <li> 当社は、利用者に対して、適宜、情報提供やアドバイスを行うことがありますが、その結果について、当社は、一切の責任を負わないものとします。また、本サイトで紹介するサービス・商品の品質、および使用した場合の効果について、いかなる保証も行っておりません。</li>
          <li> 何らかの理由により当社が責任を負う場合であっても、当社は、利用者が被った損害につき、金1万円を超えて賠償する責任を負わないものとし、また、付随的損害、間接損害、特別損害、将来の損害および逸失利益にかかる損害については、賠償する責任を負わないものとします。</li>
          <li> 本サービスまたは本サイトに関連して、利用者と他者との間において生じた取引、連絡、紛争等については、利用者は自己の費用と責任においてこれを解決するものとし、当社は、一切責任を負わないものとします。</li>
          <li> 本利用規約またはその他利用者と当社との合意が消費者契約法（平成12年法律第61号）第２条第３項の消費者契約に該当する場合には、本利用規約またはその他利用者と当社との合意のうち、当社の損害賠償責任を完全に免責する規定は適用されないものとします。この場合において、利用者に生じた損害が当社の債務不履行または不法行為に基づくときは、当社は、当該利用者が直接かつ現実に被った損害を上限として損害賠償責任を負うものとします。ただし、当社に故意または重過失がある場合に限ります。</li>
        </ol>
      </section>

      <section>
        <h2>
          第14条 個人情報の取扱い等
        </h2>
        <ol>
          <li> 当社による登録者の個人情報の取扱いについては、別途当社が定める個人情報保護方針の定めによるものとし、登録者は、当該方針に従って当社が登録者の個人情報を取り扱うことについて、同意するものとします。</li>
          <li> 当社は、登録者の取得情報を、個人を特定できない形での統計的情報として、当社の裁量で、利用および公開することができるものとし、登録者は、これに異議を唱えないものとします。</li>
        </ol>
      </section>

      <section>
        <h2>
          第15条 連絡または通知
        </h2>
        <ol>
          <li> 本利用規約の変更に関する通知その他当社から登録者に対する連絡または通知は、当社の定める方法で行うものとします。</li>
          <li> 本サービスに関する問い合わせその他登録者から当社に対する連絡または通知は、当社が指定するお問い合わせフォームから行うものとします。なお、当社は、原則として電話による連絡および来訪は受け付けておりません。</li>
        </ol>
      </section>

      <section>
        <h2>
          第16条 譲渡禁止等
        </h2>
        <ol>
          <li> 登録者は、当社の書面による事前の承諾がない限り、当社との合意またはID登録により得た地位または権利義務を第三者に譲渡、売買、名義変更、質権の設定その他の担保に供する等の行為ができないものとします。</li>
          <li> 登録者のID登録は一身専属のものとします。当社は、登録者の死亡を知り得た時点をもって、ID登録が解約されたものとみなします。</li>
        </ol>
      </section>

      <section>
        <h2>第17条 分離可能性</h2>
        <p>本利用規約のいずれかの条項またはその一部が、消費者契約法その他の法令等により無効または執行不能と判断された場合であっても、本利用規約の残りの規定および一部が無効または執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。また、本利用規約の一部が特定の利用者との間で無効とされ、または取り消された場合でも、本利用規約はその他の利用者との関係では有効とします。</p>
      </section>

      <section>
        <h2>第18条 準拠法および管轄</h2>
        <ol>
          <li> 本利用規約およびその他利用者と当社の間の合意は、日本法に準拠し、解釈されるものとします。なお、本サービスにおいて物品の売買が発生する場合であっても、国際物品売買契約に関する国際連合条約の適用を排除することに登録者は同意することとします。</li>
          <li> 本利用規約およびその他利用者と当社の間の合意に起因し、または関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。</li>
        </ol>
      </section>


      <footer className="privacy-page__footer">
        <p className="privacy-page__revision">2021年1月15日 制定</p>
        <p className="privacy-page__revision">2024年7月22日 改定</p>
      </footer>

    </>
  )
}

const MyFirstLibraryTerm = () => {
  return (

    <AppLayout isShownHeader={false}>
      <Seo title={title} isIndex={false}/>

      <article className="container page-article-main term-page">
        <TermContent/>
      </article>

    </AppLayout>
  );
}

export default MyFirstLibraryTerm

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
